import { useGlobalStore } from "@/stores/globalStore";
import { useSessionStore } from "@/stores/sessionStore";

export async function logout() {
    return await request({
      url: `/logout`,
      method: "post",
    });
}

export async function signUp(newUser){
    if (newUser.password !== newUser.password_confirmation) {
      return {code: 901};
    }
    return await publicRequest({
        url: `/makeAccount`,
        method: "post",
        data: newUser
    });
}

export async function passRecovery() {
    const userSession = useSessionStore();

    const globalStore = useGlobalStore();
    const res = await publicRequest({
        url: `/passwordForgot`,
        method: "post",
        data: { email: userSession.userLogin.email }
    });
    if(res) {
        globalStore.desactiveLoader();
        showAlertMsg("Email de recuperación enviado con éxito!");
    };
}
