export function httpError(e){
    // console.log(e);
    const code    = e.response.status;
    const message = e.response.data.message;

    switch (code) {
        case 422:
            error422(e, message);
            break;

        case 403:
            error403(e, message);
            break;

        default:
            genericError(e, message)
            break;
    }
}

function error422(e, message){
    console.log('422');
    showError({message: message});
}

function error403(e, message){
    console.log('403');
    showError({message: message});
}

function genericError(e, message){
    // console.log('Generic error');
    // console.log({msg: message});
    showError({message: message});
}