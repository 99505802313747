import { VCard } from "vuetify/components/VCard";
import { VBtn } from "vuetify/components/VBtn";
import { VSwitch } from "vuetify/components/VSwitch";
import { VImg } from "vuetify/components/VImg";

export default {
  ColumnTitle: VCard,
  VBtnAlt: VBtn,
  ThemeSwitch: VSwitch,
  ColorBorderImgAlias: VImg,
};
