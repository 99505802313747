// import '@mdi/font/css/materialdesignicons.css'
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as icons from "@/config/icons";
import { theme, aliases as componentAliases, defaults } from "@/config/vuetify";

// LA CONFIGURACION DE SASS ESTA PENDIENTE
import "@/styles/scss/vuetify/index.scss";

export const install = (app) => {
  const vuetify = createVuetify({
    display: {
      thresholds: {
        md: 1025,
        lg: 1050,
      },
    },
    icons: {
      defaultSet: "mdi",
      aliases: {
        ...aliases,
        ...icons,
      },
      sets: {
        mdi,
      },
    },
    theme,
    aliases: {
      ...componentAliases,
    },
    defaults,
  });
  app.use(vuetify);
};
