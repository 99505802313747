import { defineStore } from "pinia";

// Función para hidratar el store desde IndexedDB
const hydrateGlobalStore = () => new Promise(async (resolve) => {
  const globalStore = useGlobalStore();

  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('piniaPersistedState', 1);

      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event);
    });

    const transaction = db.transaction(['piniaPersistedState'], 'readonly');
    const objectStore = transaction.objectStore('piniaPersistedState');

    const request = objectStore.get('global');
    request.onsuccess = (event) => {
      // Verificamos que obtengamos un evento y posea target
      if(event){
        if(event.target){
          if(event.target.result){
            const data = JSON.parse(event.target.result);
            if (data) {
              // Reemplazamos el store por data
              Object.keys(data).forEach(key => {
                globalStore[key] = data[key];
              });
            }
          }
        }
      }
      resolve(); // Resuelve la promesa después de completar la operación
    };
  } catch (error) {
    console.error('Error hydrating store from IndexedDB:', error);
    resolve(); // También resuelve en caso de error para no bloquear la ejecución
  }
});

export const useGlobalStore = defineStore("global", {
  state: () => ({
    days: [
      { id: 0, name: "Lunes" },
      { id: 1, name: "Martes" },
      { id: 2, name: "Miercoles" },
      { id: 3, name: "Jueves" },
      { id: 4, name: "Viernes" },
      { id: 5, name: "Sabado" },
      { id: 6, name: "Domingo" },
    ],

    invoicePapers: [
      { id: 0, name: "Ticket 58mm", value: "58" },
      { id: 1, name: "Ticket 80mm", value: "80" },
      { id: 2, name: "Hoja A4", value: "A4" },
    ],

    selects: {},

    tableItemFnLoader: false,
    buttonLoader: false,
    buildLoader: false,
    loaderPro: false,
    isOnline: navigator.onLine,
  }),

  getters: {},

  actions: {
    async hydrate() {
      await hydrateGlobalStore();
    },
    activeLoader(){
      this.loaderPro = false;
    },
    desactiveLoader(){
      this.loaderPro = true;
    },

    async pushToDesktop(){
      await this.router.push("Desktop");
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot));
