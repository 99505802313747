import { defineStore } from "pinia";
import { useSessionStore } from "@/stores/sessionStore";
import { usePosStore } from "./posStore";

// Función para hidratar el store desde IndexedDB
const hydrateModelStore = () => new Promise(async (resolve) => {
  const modelStore = useModelStore();
  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('piniaPersistedState', 1);

      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event);
    });

    const transaction = db.transaction(['piniaPersistedState'], 'readonly');
    const objectStore = transaction.objectStore('piniaPersistedState');

    const request = objectStore.get('model');
    request.onsuccess = (event) => {
      // Verificamos que obtengamos un evento y posea target
      if(event){
        if(event.target){
          if(event.target.result){
            const data = JSON.parse(event.target.result);
            if (data) {
              // Reemplazamos el store por data
              Object.keys(data).forEach(key => {
                modelStore[key] = data[key];
              });
            }
          }
        }
      }
      resolve(); // Resuelve la promesa después de completar la operación
    };
  } catch (error) {
    console.error('Error hydrating store from IndexedDB:', error);
    resolve(); // También resuelve en caso de error para no bloquear la ejecución
  }
});

const persistConfig = ref(false);
export function initializeModelStore(persistValue) {
  persistConfig.value = persistValue;
}

const createModelStore = (config) => defineStore("model", {
  state: () => ({
    //MODELS
    accounts: [],
    accountPlans: [],
    companyTypes: [],
    accountPlanFeatures: [],
    accountPayments: [],
    accountPaymentStatuses: [],

    companies: [],
    branches: [],
    lastPosOperations: [],
    posOperations: [],
    posOpenings: [],
    pointOfSales: [],
    allPosStatuses: [],
    posStatuses: [],
    werehouses: [],
    users: [],
    accountUsers: [],
    roles: [],
    allCapabilities: [],
    capabilities: [],

    companyModules: [],
    modules: [],

    profiles: {},
    entityEmployees: [],
    sellers: [],
    entityCustomers: [],
    customerCtaCtes: [],
    customerGroups: [],
    customerOperations: [],
    entitySuppliers: [],
    supplierCtaCtes: [],
    supplierOperations: [],
    commissions: [],
    employeeObjectives: [],
    objectiveTypes: [
      {id: 1, value: 'daily', name: 'Diario'},
      {id: 2, value: 'monthly', name: 'Mensual'},
    ],
    entityCreditors: [],
    financialObligations: [],

    entityVatConditions: [],
    documentTypes: [],

    invoice: {},
    invoices: [],
    budgetReports: [],
    pendingSaleOrders: [],
    pendingInternalOrders: [],
    approvedInternalOrders: [],

    allInvoiceTypes: [],
    invoiceStatuses: [],
    invoiceConcepts: [
      {id: 1, country_id: 12, name: 'Productos', description: '', value: 1},
      {id: 2, country_id: 12, name: 'Servicios', description: '', value: 2},
      {id: 3, country_id: 12, name: 'Productos y servicios', description: '', value: 3},
    ],
    vats: [],
    taxes: [],
    invoiceInstallments: [],
    invoiceDiscounts: [],
    discountTypes: [
      { id: 1, name: 'Porcentaje', value: 'percent' },
      { id: 2, name: 'Monto fijo', value: 'fix' },
      { id: 3, name: 'Monto libre', value: 'manually' }
    ],

    allPaymentMethods: [],
    paymentStatuses: [],
    paymentDeadlines: [],
    rankingItems: [],

    expenseProducts: [],
    expenses: [],
    purchases: [],
    purchaseTypes: [],
    basicPurchaseTypes: [
      { id: 1, name: "Mercaderia", value: 'purchase' },
      { id: 3, name: "Gastos", value: 'expense' },
    ],

    pendingPurchaseOrders: [],

    //** Productos **//
    products: [],
    measurementUnits: [],
    priceLists: [],

    productBrands: [],
    productCategories: [],
    productFamilies: [],
    productMkups: [],
    productPromotions: [],
    //** Fin Productos **//

    //** Servicios **//
    services: [],
    serviceAppointments: [],
    serviceCategories: [],
    serviceTypes: [],
    serviceUnits: [],
    //** Fin servicios **//

    countries: [],
    states: [],

    customerInvoices: { invoiced: [], pending: [] },
    sellerInvoices: { invoiced: [], pending: [] },
    invoicesList: { invoiced: [], pending: [] },

    cudOperations: {
      products: 0,
      entityCustomers: 0,
      services: 0,
    },

    days: [
      { id: 0, name: "Lunes" },
      { id: 1, name: "Martes" },
      { id: 2, name: "Miercoles" },
      { id: 3, name: "Jueves" },
      { id: 4, name: "Viernes" },
      { id: 5, name: "Sabado" },
      { id: 6, name: "Domingo" },
    ],
    trueOrFalse: [
      { id: 1, value: true, name: "Si" },
      { id: 2, value: false, name: "No" },
    ],
    criticalities: [
      { id: 0, name: "Root", value: "root" },
      { id: 1, name: "Admin", value: "admin" },
      { id: 2, name: "user", value: "user" },
    ],

    columnFormats: [
      { id: 1, value: "text", name: "Texto" },
      { id: 2, value: "number", name: "Numero" },
      { id: 3, value: "array", name: "Array" },
      { id: 4, value: "soloArray", name: "soloArray" },
    ],

    columnTypes: [
      { value: "text", name: "Texto", format_id: 1 },
      { value: "number", name: "Numero", format_id: 2 },
      { value: "money", name: "Moneda", format_id: 2 },
      { value: "stock", name: "Stock", format_id: 2 },
      { value: "date", name: "Fecha", format_id: 1 },
      { value: "averageTime", name: "Tiempo promedio", format_id: 1 },
      { value: "weight", name: "Peso", format_id: 2 },
      { value: "volume", name: "Volumen", format_id: 2 },
      { value: "noDecimal", name: "Sin decimales", format_id: 2 },
      { value: "noCeros", name: "Sin ceros", format_id: 2 },
      { value: "decimal", name: "2 decimales", format_id: 2 },
      { value: "percentage", name: "Porcentaje", format_id: 2 },
    
      { value: "textPercentage", name: "Txt porcentaje", format_id: 1 },
      { value: "activeOrInactive", name: "Activo o inactivo", format_id: 1 },
      { value: "simple", name: "Simple", format_id: 3 },
      { value: "objects", name: "Objetos", format_id: 3 },
      { value: "chipsObjects", name: "Objectos Chip", format_id: 4 },
    ],

    formTypes: [
      { value: "text", name: "Texto" },
      { value: "boolean", name: "Booleano" },
      { value: "date", name: "Fecha" },
      { value: "file", name: "Archivos" },
      { value: "number", name: "Numero" },
      { value: "select", name: "DropDown" },
      { value: "hidden", name: "Oculto" },
      { value: "card", name: "Vista personalizada" },
      { value: "relation", name: "Relacion" },
    ],
    
    formFormat: [
      { value: "text", name: "Texto", parent: "text" },
      { value: "email", name: "Email", parent: "text" },
      { value: "textArea", name: "Area de texto", parent: "text" },
      { value: "phone", name: "Telefono", parent: "text" },
      { value: "password", name: "Password", parent: "text" },
      { value: "color", name: "Color", parent: "text" },
    
      { value: "money", name: "Moneda", parent: "number" },
      { value: "stock", name: "Stock", parent: "number" },
      { value: "noDecimal", name: "Sin decimales", parent: "number" },
      { value: "decimal", name: "2 decimales", parent: "number" },
      { value: "percent", name: "Porcentaje", parent: "number" },
      { value: "range", name: "Rango", parent: "number" },
    
      { value: "select", name: "DropDown", parent: "select" },
      { value: "selectFromSelf", name: "DropDown from self", parent: "select" },
      { value: "selectOneToMany", name: "DropDown one to many", parent: "select" },

      { value: "datepicker", name: "Fecha y hora", parent: "date" },
      { value: "datepickerDate", name: "Fecha", parent: "date" },
      { value: "datepickerTime", name: "Hora", parent: "date" },
    
      { value: "trueOrFalse", name: "Si o no", parent: "boolean" },
      { value: "checkbox", name: "Checkbox", parent: "boolean" },
      { value: "radioButton", name: "Boton radio", parent: "boolean" },
      { value: "hidden", name: "Oculto", parent: "boolean" },
    
      { value: "uploadFile", name: "Subir archivos", parent: "file" },
      { value: "uploadImage", name: "Subir imagen", parent: "file" },
    
      { value: "promotion", name: "Promociones", parent: "card" },
    
      { value: "card", name: "Card", parent: "relation" },
      { value: "table", name: "Tabla", parent: "relation" },
    ],
  }),

  persist: config,
  // persist: true,
  // persist: { excludes: ['customerCtaCtes', 'supplierCtaCtes', 'invoice', 'invoices', 'budgetReports', 'pendingSaleOrders', 'pendingInternalOrders', 'approvedInternalOrders'] },
  // persist: { paths: ['states', 'countries', 'measurementUnits', 'purchaseTypes', 'allPaymentMethods', 'invoiceDiscounts', 'invoiceInstallments', 'taxes', 'vats', 
  // 'invoiceStatuses', 'allInvoiceTypes', 'documentTypes', 'entityVatConditions', 'posStatuses', 'allPosStatuses', 'companyTypes'] },

  getters: {
    activeProducts() {
      return usePosStore().selectedPos.config?.defaultConcept === 1 ? this.products.filter((i) => i.status === 1) : 
      this.products.filter((i) => i.status === 1).concat(this.services.filter((i) => i.status === 1));
    },

    userPaymentMethods(){
      return useSessionStore().user?.entity?.entity_employee.payment_methods ?? [];
    },

    rolePaymentMethods(){
      return useSessionStore().user.role?.payment_methods ?? [];
    },

    paymentMethods(){
      return usePosStore().selectedCompany.config?.getPaymentMethodsBy === 'employee' ? 
      this.userPaymentMethods : this.rolePaymentMethods;
    },

    userInvoiceTypes() {
      return useSessionStore().user?.entity?.entity_employee.invoice_types ?? [];
    },

    roleInvoiceTypes() {
      return useSessionStore().user?.role?.invoice_types ?? [];
    },

    invoiceTypes(){
      return usePosStore().selectedCompany.config?.getInvoiceTypesBy === 'employee' ? 
      this.userInvoiceTypes : this.roleInvoiceTypes;
    },

    companyInvoiceTypes(){
      return usePosStore().selectedCompany.invoice_types ?? [];
    },

    companyPaymentMethods(){
      return usePosStore().selectedCompany.payment_methods ?? [];
    },

    receiptInvoiceTypes(){
      return this.invoiceTypes.filter((i) => TRAITFEANDNOFE.includes(i.id))
    },

    ncInvoiceTypes(){
      return this.companyInvoiceTypes.filter((i) => feAndNoFeNC.includes(i.id))
    },
  },

  actions: {
    async hydrate() {
      await hydrateModelStore();
    },

    availablePaymentMethods(withCC){
      return this.paymentMethods.filter((p) => 
      {
        if(!withCC){
          if(p.id !== 4) return p;
        } else {
          return p;
        }
      }
      )
    },

    // RESET FUNCTION
    async resetState() {
      this.accounts = [];
      this.accountPlans = [];
      this.companyTypes = [];
      this.accountPlanFeatures = [];
      this.accountPayments = [];
      this.accountPaymentStatuses = [];
  
      this.companies = [];
      this.branches = [];
      this.lastPosOperations = [];
      this.posOperations = [];
      this.posOpenings = [];
      this.pointOfSales = [];
      this.allPosStatuses = [];
      this.posStatuses = [];
      this.werehouses = [];
      this.users = [];
      this.roles = [];
      this.allCapabilities = [];
      this.capabilities = [];
  
      this.companyModules = [];
      this.modules = [];
  
      this.profiles = {};
      this.entityEmployees = [];
      this.sellers = [];
      this.entityCustomers = [];
      this.customerCtaCtes = [];
      this.customerGroups = [];
      this.customerOperations = [];
      this.entitySuppliers = [];
      this.supplierCtaCtes = [];
      this.supplierOperations = [];
      this.commissions = [];
      this.entityCreditors = [];
      this.financialObligations = [];
  
      this.entityVatConditions = [];
      this.documentTypes = [];
  
      this.invoice = {};
      this.invoices = [];
      this.pendingSaleOrders = [];
      this.pendingInternalOrders = [];
      this.approvedInternalOrders = [];
  
      this.allInvoiceTypes = [];
      this.invoiceStatuses = [];
      
      this.vats = [];
      this.taxes = [];
      this.invoiceInstallments = [];
      this.invoiceDiscounts = [];

      this.allPaymentMethods = [];
      this.paymentStatuses = [];
      this.paymentDeadlines = [];
      this.rankingItems = [];

      this.expenseProducts = [];
      this.expenses = [];
      this.purchases = [];
      this.purchaseTypes = [];

      this.pendingPurchaseOrders = [];

      this.products = [];
      this.measurementUnits = [];
      this.priceLists = [];
  
      this.productBrands = [];
      this.productCategories = [];
      this.productFamilies = [];
      this.productMkups = [];
      this.productPromotions = [];
  
      this.countries = [];
      this.states = [];

      this.customerInvoices = { invoiced: [], pending: [] };
      this.sellerInvoices = { invoiced: [], pending: [] };
      this.invoicesList = { invoiced: [], pending: [] };
    }
  },
});

// Función para obtener la instancia del store
export const useModelStore = () => {
  if(!persistConfig.value){
    console.log(persistConfig.value);
    console.trace();
  }

  return createModelStore(persistConfig.value)();
};

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useModelStore, import.meta.hot));
