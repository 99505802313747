import { router } from "@/plugins/router";

export function goTo(url) {
  router.push(`/${url}`);
}

export const appMounted = ref(false);
export const appBuilded = ref(false);

export function showReceipt() {
  dialogReceipt.value = true;
}