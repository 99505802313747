import axios from "axios";
import { showError } from "@/utils/methods";
import { useGlobalStore } from "@/stores/globalStore";

/** Create an Axios instance. */
function createService() {
  // Create an axios instance named "service"
  const service = axios.create();

  // Request interceptor
  service.interceptors.request.use(
    (config) => config,
    // Failed to send request
    (error) => Promise.reject(error)
  );

  // Response interceptor (Adjust according to specific business requirements)
  service.interceptors.response.use(
    (response) => {
      // apiData is the data returned by the API
      const apiData = response.data;

      return apiData;
    },
    (error) => {
      // "status" is the HTTP status code
      const status = error?.response?.status ?? undefined;
      // const status = get(error, "response.status");

      switch (status) {
        case 400:
          error.message = "Bad request";
          break;
        case 401:
          // When the token expires, log out and force refresh the page (will redirect to the login page)
          break;
        case 403:
          error.message = "Forbidden";
          break;
        case 404:
          error.message = "Request address not found";
          break;
        case 408:
          error.message = "Request timeout";
          break;
        case 500:
          error.message = "Internal server error";
          break;
        case 501:
          error.message = "Service not implemented";
          break;
        case 502:
          error.message = "Bad gateway";
          break;
        case 503:
          error.message = "Service unavailable";
          break;
        case 504:
          error.message = "Gateway timeout";
          break;
        case 505:
          error.message = "HTTP version not supported";
          break;
        default:
          error.message = "Hubo un error desconocido.";
          break;
      }

      httpError(error);

      // return false;
      return Promise.reject(false);
    }
  );
  return service;
}

function createRequestFunction(service) {
  return async function (configRequest) {
    const configDefault = {
      headers: {
        // Authorization: "Bearer " + sessionStore.token,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      withCredentials: true,
      baseURL: import.meta.env.VITE_BaseApiURL,
      data: {},
    };

    if (Array.isArray(configRequest)) {
      // Make all the requests in parallel
      const responses = await axios.all(configRequest.map((config) => {
        const post = { ...config };
        service({ ...configDefault, ...post })
        }
      ));
      return responses.map((response) => response.result);
    }
    const activeLoader = configRequest.activeLoader ?? true;
    if(activeLoader) useGlobalStore().activeLoader();

    configRequest.data = { ...configRequest.data };
    const response = await service({ ...configDefault, ...configRequest });

    if(configRequest.method === "put") console.log("Registro actualizado!");

    if(!response) return false;

    if (response.data) {
      return response.data.result;
    }
    return response.result;
  };
}

/** Instance for network request */
const service = createService();

/** Method for network request */
export const publicRequest = createRequestFunction(service);
