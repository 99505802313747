import { router } from "@/plugins/router";
import { createPinia } from "pinia";
import { createPersistedState } from '@/config/piniaPersist.js';

export const install = async (app) => {
  const pinia = createPinia();

  pinia.use(({ store }) => {
    store.router = markRaw(router);
  });

  pinia.use(createPersistedState());
  app.use(pinia);
};
