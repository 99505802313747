<template>
  <VApp :theme="currentTheme">
    <router-view />
    <div class="fill-height" v-if="dialogSW">
      <PwaUpdating v-model="dialogSW" :alertSWMs="alertSWMsg" style="position:absolute;" />
    </div>
    <div class="fill-height" v-if="!globalStore.loaderPro && !dialogSW">
      <LoaderPro v-model="globalStore.loaderPro" style="position:absolute;" />
    </div>
  </VApp>
</template>

<script setup>
import { useAppStore } from "@/stores/appStore";
import { useGlobalStore } from "@/stores/globalStore";
import { dialogSW, alertSWMsg, alertSWColor } from "/src/composables/Modals";

axios.defaults.baseURL = import.meta.env.VITE_BaseApiURL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Configura CORS a nivel de cliente
axios.defaults.withCredentials = true;

const globalStore = useGlobalStore();

const { currentTheme } = toRefs(useAppStore());
useTailwindTheme(currentTheme);
</script>
