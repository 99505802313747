const dialogAlertMessage = ref(false);
const dialogFormAlertMessage = ref(false);

const alertMessage = ref({});
const alertMessageColor = ref("");

const dialogSW = ref(false);
const alertSWMsg = ref("Hay actualizaciones disponibles!");
const alertSWColor = ref("bg-grey");

const dialogSelectedItem = ref({});
const dialogDeleteStatus = ref(false);
const modalDeleteTitle = ref("");
const modalDeleteMessage = ref({});
const deleteItem = ref({});
const deleteItemText = ref("");

const dialogCtaCteMov = ref(false);
const dialogCtaCteModel = ref(false);
const dialogInvoicesStatus = ref(false);
const dialogSupplierCtaCteMov = ref(false);

const dialogEmployeeVisit = ref(false);

const dialogReceipt = ref(false);
const dialogTitle = ref("");

const dialogPriceAdjust = ref(false);
const dialogPriceList = ref(false);
const dialogInventory = ref(false);
const dialogDelivery = ref(false);
const dialogSyncPriceList = ref(false);
const dialogAccoutStatus = ref(false);

const dialogSetSuscription = ref(false);

const dialogConfirmation = ref(false);
const dialogCheckDelete = ref(false);
const dialogClosePOS = ref(false);

const createFormStatus = ref(false);
export {
  dialogAlertMessage,
  dialogFormAlertMessage,
  alertMessage,
  alertMessageColor,
  dialogSW,
  alertSWMsg,
  alertSWColor,
  dialogSelectedItem,
  dialogDeleteStatus,
  modalDeleteTitle,
  modalDeleteMessage,
  deleteItem,
  dialogCheckDelete,
  deleteItemText,
  dialogConfirmation,
  dialogCtaCteMov,
  dialogCtaCteModel,
  dialogInvoicesStatus,
  dialogSupplierCtaCteMov,
  dialogEmployeeVisit,
  dialogReceipt,
  dialogTitle,
  dialogPriceAdjust,
  dialogPriceList,
  dialogInventory,
  dialogDelivery,
  dialogSyncPriceList,
  dialogAccoutStatus,
  dialogSetSuscription,
  dialogClosePOS,
  createFormStatus
};
