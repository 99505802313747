import rootApp from "@/App.vue";
import { router } from "@/plugins/router";
import pluginsImporter from "@/utils/pluginsImporter";

import '@vuepic/vue-datepicker/dist/main.css';
import "@/styles";

// Importing custom fonts using the vite-plugin-fonts library
import "unfonts.css";

export const instanciateVue = async () => {
  let app;
  app = createApp(rootApp);

  // install all @/modules
  pluginsImporter(app);
  // Mount Vue after autoImportModules and setStoreUser is done.

  await router.isReady();

  appMounted.value = true;
  // Mount Vue after auth and modules are done.
  app.mount("#app");
};
