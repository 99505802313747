export default {
  ColumnTitle: { class: "elevation-6", height: 48 },

  VTextField: {
    hideDetails: "auto",
    density: "comfortable",
    variant: "outlined",
    color: "field-outline-focus",
    bgColor: "text-field-background",
    persistentPlaceholder: true,
  },

  VTextarea:{
    variant: "outlined",
    density:"comfortable",
    color: "field-outline-focus",
    bgColor: "text-field-background",
    persistentPlaceholder: true,
  },

  VBtnAlt: {
    ripple: false,
    color: "primary-button",
    class: "rounded-lg",
    border: true,
  },

  VToolbar: {
    VBtn: { variant: "elevated" },
  },

  VAppBar: {
    flat: true,
    border: true,
    density: "comfortable",
  },

  ThemeSwitch: {
    ripple: false,
    hideDetails: true,
    inset: true,
    trueIcon: "$mdiLightbulbOff",
    falseIcon: "$mdiLightbulbOn",
    trueValue: "light",
    falseValue: "dark",
  },

  VCard: {
    border: true,
    elevation: 4,
    class: "rounded-0",
    ripple: false,
  },
};
